import "./App.css";
import Approuter from "./router/Approuter";

function App() {
  return (
    <div className="App">
      <Approuter />
    </div>
  );
}

export default App;
